<template>

  <!-- Start Content-->
  <div style="min-height:600px;">

    
    <div class="responsive-table-plugin btn-toolbar align-items-center justify-content-between">

      <div class="form-inline md-form form-sm toolbar-search" v-if="isadmin">
        <div class="lang" v-html="lan.title"></div>:<input class="form-control mr-1 w-80" type="text" v-model="listStrage.title" >
        <div class="lang" v-html="lan.content"></div>:<input class="form-control mr-1 w-80" type="text" v-model="listStrage.content" >
        <div class="lang" v-html="lan.release_date"></div>: <datepicker v-model="listStrage.release_date_start" /> <div class="lang" v-html="lan.to"></div> <datepicker v-model="listStrage.release_date_end" />
        <button type="button" class="btn btn-white" @click="search">
          <i class="fas fa-search" aria-hidden="true"></i>
        </button>&nbsp;&nbsp;&nbsp;&nbsp;
        <button type="button" class="btn btn-white" @click="add()">
          <i class="fas fa-plus" aria-hidden="true"></i><div class="lang" v-html="lan.add"></div>
        </button>
      </div>
      <div class="form-inline toolbar-display">
        
        <div class="mr-2 toolbar-display-count">
          <span class="mr-1">表示件数</span>
          <div>
            <select class="form-control mt-0" v-model.lazy="listStrage.limit" @change="search">
              <option>10</option>
              <option>50</option>
              <option>100</option>
              <option>200</option>
              <option>500</option>
            </select>
          </div>
        </div>

       

      </div>
    </div>

      
      <div class="d-flex justify-content-center p-5" v-if="isLoading">
        <div class="spinner-border m-12" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
   
    <div class="responsive-table-plugin" v-if="!jsonErr">

      <div class="table-rep-plugin">
        <div class="table-responsive">
          <table class="table table-striped focus-on">
            <thead>
              <tr>
                <th v-for="tableHeader in tableHeaders" :key="tableHeader" :class="'table-row-' + tableHeader">{{ tableHeader }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="jsonObj.count ">
              <tr v-for="(obj, idNum) in listData" :key="idNum" :class="{ 'focused' : isFocus === idNum }" @click="toggleFocus(idNum)" @child="toggleFocus(idNum)">
                <list-table-row-notification :row="obj" :checkList="checkKeys" />
                <td class="table-focus-on-td" v-if="obj.send_date==null && isadmin==1">
                  <transition transition name="slide-btn">
                    <router-link v-if="isFocus === idNum" :to="'/notification?cid='+(obj[viewParams.articleParamId]!=undefined ? obj[viewParams.articleParamId]:0)+'&data_type='+viewParams.dataType" class="btn btn-default"><div class="lang" v-html="lan.update"></div> <i class="mdi mdi-arrow-right-bold"></i></router-link>
                  </transition>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="p-2">
            <code v-if="!jsonObj.total_count">表示データがありません。</code>
          </div>
        </div> <!-- end .table-responsive -->
      </div> <!-- end .table-rep-plugin-->

      <div class="d-flex justify-content-end mt-1" v-if="jsonObj.total_count">
        <span>{{ (listStrage.paged - 1) * listStrage.limit + 1 }}</span>
        &nbsp;~&nbsp;
        <span>{{ (listStrage.paged - 1) * listStrage.limit + jsonObj.count }}</span>
        &nbsp;/&nbsp;
        <span>{{ jsonObj.total_count }}</span>
      </div>
      <div class="d-flex justify-content-end mt-1" v-else>
        <span>0</span>
        &nbsp;~&nbsp;
        <span>0</span>
        &nbsp;/&nbsp;
        <span>0</span>
      </div>

      <ul class="pagination pagination-split justify-content-end flex-wrap footable-pagination mt-1" v-if="jsonObj.total_count">
        <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('first')">«</a></li>
        <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('prev')">‹</a></li>
        <li class="footable-page mb-1" :class="{'active': num === listStrage.paged, 'hide': pageHide(num) }" v-for="num in maxPage" :key="num"><a href="#" @click.prevent.stop="pagenateNum(num)">{{ num }}</a></li>
        <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('next')">›</a></li>
        <li class="footable-page-arrow mb-1"><a href="#" @click.prevent.stop="pagenateKey('last')">»</a></li>
      </ul>
    </div> <!-- end .responsive-table-plugin-->
    <div v-else>
      <code>
      データ取得に失敗しました。
      </code>
    </div>
      
  </div> <!-- container -->

</template>

<script>
import datepicker from 'vue3-datepicker'
import ListTableRowNotification from './ListTableRowNotification.vue'
import axios from 'axios';
import { inject } from 'vue';

export default {
  name: 'ListTableRecommend',
  props: {
    viewParams: {
      name: {
        type: String,
        default: ''
      },
      apiUrl: {
        type: String,
        default: '',
      },
      dataType: {
        type: String,
        default: 'function',
      },
      articleView: String,
      articleParamId: String
    },
  },
  components: {
    ListTableRowNotification,datepicker
  },
  data() {
    return {
      isadmin:0,
      offset: 0,
      listStrage: {
        title: '',
        content: '',
        release_date_start: '',
        release_date_end: '',
        limit: 100,
        paged: 1,
      },
      maxPage: 0,
      checkKeys: [],
      jsonObj: {
        offset: 0,
        count: 0,
        total_count: 0,
        data: [Object]
      },
      tableHeaders: [],
      listData: [Object],
      isFocus: -1,
      ddMenu: false,
      jsonErr: false,
      isLoading: true,
      downloadAlert: '',
      lan:inject('global').language_data,lan_help:inject('global').language_help_data,
      cur_lan:'jp',
    }
  },
  mounted() {
    this.init();
    if(localStorage.getItem("email")=='admin@cbredata.com'){
      this.isadmin=1;
    }
    
    
  },
  methods: {
    add(){
      location.href="/#/notification?data_type="+this.viewParams.dataType;
    },
    init(){
//let language_data=JSON.parse(localStorage.getItem('language_data'));
    //this.lan=language_data;
    console.log(this.viewParams);
      axios.get(this.viewParams.apiUrl, {
          params: {
            offset: (this.listStrage.paged - 1) * this.listStrage.limit, // 開始位置
            limit: this.listStrage.limit, //表示件数
            data_type:this.viewParams.dataType,
            title:this.listStrage.title,
            content:this.listStrage.content,
            release_date_start:this.listStrage.release_date_start,
            release_date_end:this.listStrage.release_date_end,
          
          }
        })
        .then(response => (
            this.jsonObj = response.data,
            this.tableHeaders = this.jsonObj.headers,
            this.checkKeys= Object.keys(this.tableHeaders),
            console.log(this.checkKeys),
            this.listData = response.data.data,
            this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit),
            this.isLoading = false
          )
        )
        .catch(error => {
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
          }
        );
    },
    toggleDDMenu: function() {
      this.ddMenu = !this.ddMenu
    },
  
    toggleFocus: function(idNum) {
      this.isFocus = idNum;
    },
    search: function() {
      this.isLoading = true
      localStorage.setItem(this.viewParams.name + '_limit', this.listStrage.limit)
    
      sessionStorage.setItem(this.viewParams.name + '_paged', 1)
      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: 0, //開始位置
            limit: this.listStrage.limit, //表示件数
            data_type:this.viewParams.dataType,
            title:this.listStrage.title,
            content:this.listStrage.content,
            release_date_start:this.listStrage.release_date_start,
            release_date_end:this.listStrage.release_date_end,
          }
        })
        .then(response => (
            this.jsonObj = response.data,
            this.tableHeaders = this.jsonObj.headers,
            this.listData = this.jsonObj.data,
            this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit),
            this.listStrage.paged = 1,
            this.isLoading = false
          )
        )
        .catch(error => {
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
          }
        );
    },
    pagenateNum: function(num) {
      this.isLoading = true
      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: (num - 1) * this.listStrage.limit, //開始位置
            limit: this.listStrage.limit, //表示件数
            data_type:this.viewParams.dataType,
            title:this.listStrage.title,
            content:this.listStrage.content,
            release_date_start:this.listStrage.release_date_start,
            release_date_end:this.listStrage.release_date_end,
          }
        })
        .then(response => (
            this.jsonObj = response.data,
            this.tableHeaders = this.jsonObj.headers,
            this.listData = this.jsonObj.data,
            this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit),
            this.listStrage.paged = Math.round(this.jsonObj.offset / this.listStrage.limit) + 1,
            sessionStorage.setItem(this.viewParams.name + '_paged', this.listStrage.paged),
            this.isLoading = false
          )
        )
        .catch(error => {
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
          }
        );
    },
    pagenateKey: function(key) {
      switch(key) {
        case 'next':
          if( this.listStrage.paged < this.maxPage ) {
            this.isLoading = true
            this.offset = this.listStrage.limit * this.listStrage.paged
          }
          break
        case 'prev':
          if( this.listStrage.paged > 1 ) {
            this.isLoading = true
            this.offset = this.listStrage.limit * (this.listStrage.paged - 2)
          }
          break
        case 'first':
          if( this.listStrage.paged > 1 ) {
            this.isLoading = true
            this.offset = 0
          }
          break
        case 'last':
          if( this.listStrage.paged < this.maxPage ) {
            this.isLoading = true
            this.offset = this.listStrage.limit * (this.maxPage - 1)
          }
          break
        default:
          this.isLoading = false
          console.log('Non-existent key')
      }
      if(this.isLoading) {
        axios
          .get(this.viewParams.apiUrl, {
            params: {
              offset: this.offset, //開始位置
              limit: this.listStrage.limit, //表示件数
              data_type:this.viewParams.dataType,
              title:this.listStrage.title,
            content:this.listStrage.content,
            release_date_start:this.listStrage.release_date_start,
            release_date_end:this.listStrage.release_date_end,
            }
          })
          .then(response => (
              this.jsonObj = response.data,
              this.tableHeaders = this.jsonObj.headers,
              this.listData = this.jsonObj.data,
              this.maxPage = Math.ceil(this.jsonObj.total_count / this.listStrage.limit),
              this.listStrage.paged = Math.round(this.jsonObj.offset / this.listStrage.limit) + 1,
              sessionStorage.setItem(this.viewParams.name + '_paged', this.listStrage.paged),
              this.isLoading = false
            )
          )
          .catch(error => {
            console.log(error),
            this.jsonErr = true,
            this.isLoading = false
            }
          );
      }
    },
    pageHide: function(num) {
      if( num < this.listStrage.paged - 4 || num > this.listStrage.paged + 4 ) {
        return true
      }
    },
    listDownload: function() {
      let xmldata
      axios
        .post(this.viewParams.apiUrl + 'download', 
        {
          data: this.listData,
          items: this.checkKeys
        },
        {
          responseType: 'blob'
        }
        )
        .then(response => {
            xmldata = response.data
            this.$nextTick(() => {
              let blob = new Blob([xmldata])
              let link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = 'corps.xlsx'
              link.click()
            })
            this.downloadAlert = ''
            this.isLoading = false
          }
        )
        .catch(error => {
            this.downloadAlert = 'ダウンロードに失敗しました。'
            console.log(error)
            this.isLoading = false
          }
        );
      
    }
  },
  watch: {
    viewParams() {
      console.log(this.viewParams);
       this.init();
    },
    isLoading: function() {
      if(!this.isLoading) {
        this.$nextTick(() => {
          window.parent.postMessage(['h'], "*")
        })
      } else {
        //ローディング開始のタイミングでisFocusをリセット
        this.isFocus = -1
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.form-inline {
  flex-wrap: nowrap;
}

.toolbar-download {
  margin-left: auto;
  margin-right: 1.5rem;
}

.toolbar-display-count {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 576px) {

  .toolbar-search {
    flex: 1;
  }

  .toolbar-download {
    justify-content: flex-end;
    margin-bottom: .75rem;
    margin-right: 0;
    order: -1;
    width: 100%;
  }

  .toolbar-display {
    justify-content: flex-end;
    margin-top: .5em;
    width: 100%;
  }

}

.form-search {
  position: relative;
}

.form-search::before {
  content: "\f002";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  height: 1em;
  width: 1em;
}

.ddmenu-modal {
  position: relative;
  z-index: 128;
}

.ddmenu {
  background: #fff;
  border-radius: .15rem;
  left: 50% !important;
  padding: 2.5rem 2.5rem 1.5rem;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  width: 80vw;
  z-index: 16;
}

.ddmenu .btn-dd-close {
  position: absolute !important;
  right: -.5rem;
  top: -.5rem;
}

.dropdown-menu.show {
  align-content: flex-start;
  animation: none !important;
  display: flex;
  flex-wrap: wrap;
  left: auto !important;
  margin-bottom: 1rem;
  max-height: 40rem;
  overflow-y: scroll !important;
  position: static !important;
  top: auto !important;
  width: 100%;
}

.dropdown-menu.show li {
  flex: 0 1 32.5%;
  word-break: break-all;
}

.ddmenu-bg {
  background: rgba(0, 0, 0, .25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.fade-leave-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-active {
  transition: .25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

@media screen and (max-width: 768px) {

  .dropdown-menu.show li {
    flex-basis: 50%;
  }

}

@media screen and (max-width: 500px) {

  .dropdown-menu.show li {
    flex-basis: 100%;
  }

}

.table-responsive {
  overflow: auto !important;
}

table.focus-on tbody tr:hover, table.focus-on tfoot tr:hover {
  cursor: unset;
}

.table thead tr:last-child th {
  background: #fff;
  box-shadow: 0 -.1rem #dee2e6 inset;
  border-bottom: none;
  position: sticky;
  top: -1px;
  z-index: 8;
}

.table.table-striped td {
  min-width: 14.5em;
  white-space: normal !important;
  word-wrap: break-word;
}

.table.table-striped .table-focus-on-td {
  align-items: center;
  min-width: auto;
  padding: .35rem !important;
  position: sticky;
  right: 0;
}

.table-focus-on-td .btn {
  white-space: nowrap;
  transition: .15s;
}

// transition
.slide-btn-leave-to.btn {
  opacity: 0;
}

.slide-btn-enter-from.btn {
  transform: translateX(-1rem);
  opacity: 0;
}

.slide-btn-enter-to.btn {
  transform: translateX(0);
}

.footable-page.hide {
  display: none;
}
.v3dp__datepicker{display: inline-block;}
  .v3dp__datepicker input{
    display: block;
    width: 100%;
    height: calc(1.5em + .9rem + 2px);
    padding: .45rem .9rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .2rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
</style>