<template>
  <div>
     <headers />
     <div class="mb" style="padding:70px 15px 0 15px;">
      
        <div class="col-12">
          <div class="page-title-box">
            <h4 class="page-title"><div class="lang" v-html="lan.notification" ></div>
            <router-link :to="{path:'/'}" class="btn btn-primary waves-effect waves-light btn-back-fixed">
              <i class="mdi mdi-arrow-left-bold"></i> 一覧
            </router-link>
            </h4>
          </div>
        </div>
    
      <div class="row">
        <div class="col-12">
          <div class="tab-box" >
              <ul class="tab-head nav nav-tabs">
                <li class="nav-item" data-id="tc1" @click="tab('tc1')" v-bind:class="[curindex=='tc1' ? 'nav-item active show':'nav-item']">
                  <a data-toggle="tab"   v-bind:class="[curindex=='tc1' ? 'nav-link active':'nav-link']" aria-expanded="true"><div class="lang" v-html="lan.function" ></div></a>
                </li>
                <li v-if="code!=''" class="nav-item" data-id="tc1-2" @click="tab('tc2')" v-bind:class="[curindex=='tc2' ? 'nav-item active show':'nav-item']">
                  <a data-toggle="tab"   v-bind:class="[curindex=='tc1-2' ? 'nav-link active':'nav-link']" aria-expanded="true"><div class="lang" v-html="lan.content" ></div></a>
                </li>  
                
              </ul>
              
          </div>
        </div>
      </div>
      <div class="row">
      
        <div class="tab-content" style="width:100%;background:#fff">
        
            <div class="embed-responsive-16by9 tab-pane show active tc1" id="tc1" v-if="curindex=='tc1'">
                <div class="col-12">
                  <div class="card-box">
                    <list-table :viewParams="viewParams" />
                  </div>
                </div>
            </div>
            <div class="embed-responsive-16by9 tab-pane show active tc2" id="tc2" v-if="curindex=='tc2'">
                <div class="col-12">
                  <div class="card-box">
                    <list-table :viewParams="viewParams2" />
                  </div>
                </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Headers from '@/components/Headers.vue'
import ListTable from '@/components/ListTableNotification.vue'
import router from '@/router'
import { inject } from 'vue';

export default {
  name: 'CorpList',
  components: {
    ListTable,Headers
  },
  data() {
    return {
      title:'',
      curindex:'tc1',
      viewParams: {
        name: 'corp1',
        apiUrl: '/v1/corps_group/',
        articleView: 'CorpGroup',
        articleParamId: 'id',
        dataType:'function',
        tolink:'',
        gType:''
      },
       viewParams2: {
        name: 'corp1',
        apiUrl: '/v1/corps_group/',
        articleView: 'CorpGroup',
        articleParamId: 'id',
        dataType:'content',
        tolink:'',
        gType:''
      },
      lan:inject('global').language_data,lan_help:inject('global').language_help_data,
    }
  }, mounted() {
    const { currentRoute } = router
    //let language_data=JSON.parse(localStorage.getItem('language_data'));
    //this.lan=language_data
    if(currentRoute.value.query.type==undefined || currentRoute.value.query.type==''|| currentRoute.value.query.titypetle=='function'){
      
     localStorage.setItem('corplistgroup_tolink','corp_list_group');
      localStorage.setItem('corplistgroup_type','function')
      this.curindex='tc1';
      
    }else{
    
      localStorage.setItem('corplistgroup_tolink','corp_list_group');
      localStorage.setItem('corplistgroup_type','content')
     this.curindex='tc2';
      
    }
  },methods:{
    tab:function(d){
      this.curindex=d;
      
    }
  }
}
</script>